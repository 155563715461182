<template>
  <div class="manage-students-page">
    <div key="content" class="manage-students-page__wrapper">
      <div class="manage-students-page__info-box info-box container-fluid">
        <div class="info-box__title">{{ $t('header.checkingHomeworks') }}</div>
      </div>
      <div class="manage-students-page__back-button container-fluid">
        <BackButton
          class="navigation-back"
          :label="$t('buttonLabels.backToList')"
          routeToPush="/manage-homeworks-table"
        />
      </div>
      <div class="container-fluid">
        <div class="manage-students-page__content">
          <div class="check-homeworks">
            <div class="check-homeworks__filter-items">
              <div
                class="filter-item"
                v-for="(filter, index) in filters"
                :key="filter"
                :class="{
                  'filter-item--active': activeFilterIndex === index,
                  'with-dash': index === 0,
                }"
                @click="choseFilter(index)"
              >
                <div v-if="isShown(index)">
                  {{ $t(filter) }}
                </div>
                <span v-if="activeFilterIndex === index" class="primary-dash" />
              </div>
            </div>
            <transition name="component-fade" mode="out-in">
              <div v-if="loading" key="loader" class="f-center h-100 flex-grow-1 mt-auto">
                <div>
                  <Loader />
                </div>
              </div>
              <div v-else class="check-homeworks__list-of-homeworks">
                <TaskDropdown />
                <HomeworkCard
                  v-for="homework in filteredHomeworks"
                  :key="homework.chatId"
                  :homework="homework"
                />
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BackButton from '@/components/Buttons/BackButton.vue';
import HomeworkCard from '@/components/Cards/HomeworkCard.vue';
import Loader from '@/components/Loader/Loader.vue';
import TaskDropdown from '@/components/TaskDropdown.vue';
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'CheckHomeworksPage',
  components: {
    TaskDropdown,
    Loader,
    HomeworkCard,
    BackButton,
  },
  data() {
    return {
      filters: ['links.chosenAnswer', 'links.requireCheck', 'label.checked', 'links.allAnswers'],
      activeFilterIndex: 3,
      loading: true,
    };
  },
  async created() {
    if (this.$route.query?.homeworkId) {
      this.activeFilterIndex = 0;
    }
    this.loading = true;
    if (!this.chats?.length) {
      await this.fetchChats();
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters({ chats: 'chats/getChatList' }),
    filteredHomeworks() {
      if (this.$route.query?.homeworkId) {
        return this.homeworks.filter((t) => t.chatId === this.$route.query?.homeworkId);
      }
      if (this.activeFilterIndex === 1) {
        return this.homeworks.filter((t) => t.status === ActivityStatuses.doing);
      }
      if (this.activeFilterIndex === 2) {
        return this.homeworks.filter((t) => t.status === ActivityStatuses.rejected
        || t.status === ActivityStatuses.done);
      }
      return this.homeworks;
    },
    homeworks() {
      const userChats = [];
      this.chats.forEach((value) => {
        const homework = {};
        homework.courseName = value.Course.name;
        homework.chatId = value.TaskChatID;
        homework.taskName = value.Task.name;
        homework.userFullName = `${value.User.firstName} ${value.User.lastName}`;
        homework.email = value.User.username;
        homework.status = value.Statistics.status;
        if (value.Task.meta?.attempts) {
          homework.attempts = value.Task.meta.attempts;
        }
        if (value.Statistics.meta) {
          homework.usedAttempts = value.Statistics.meta[0].Value;
        }
        homework.max_score = value.Task.max_score;
        userChats.push(homework);
      });
      return userChats;
    },
  },
  methods: {
    ...mapActions('chats', ['fetchChats']),
    choseFilter(index) {
      if (index !== 0) {
        this.$router.replace({ query: {} }).catch(() => {});
      }
      this.activeFilterIndex = index;
    },
    isShown(index) {
      if (index === 0) {
        return this.activeFilterIndex === 0;
      }
      return true;
    },
  },
};
</script>
